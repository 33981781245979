:global(.modal) {
  .modalMain {
    min-height: 100vh !important;
    width: 500px !important;
    margin-right: unset;
    margin-top: unset;
    margin-bottom: unset;
    animation: slide-out 0.2s forwards;
    -webkit-animation: slide-out 0.2s forwards;
    border: 0 !important;
    overflow-x: hidden;
    overscroll-behavior: unset;
    height: 100%;
    > div {
      min-height: 100vh !important;
      border-radius: unset;
    }
  }

  .modalContainer {
    padding: 32px 32px 32px !important;
    background: white;
    border: 0 !important;
  }

  .modalHeader {
    display: flex;
    justify-content: space-between;
  }

  .mainpopupHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .modalBody {
    padding: 32px !important;
    background: white;
  }

  .modalSection {
    display: flex;
    justify-content: right;
  }

  .hideContainer {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .hideLabel {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 140%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #7e98ba;
  }

  .diagnosticsButton {
    display: flex;
    align-items: center;
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 140%;
    text-align: right;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #ffffff;
    background: #346bd1;
    border: 1px solid #346bd1;
    box-sizing: border-box;
    border-radius: 28px;
  }

  .defImage {
    height: 70px;
    width: 70px;
    border-radius: 50%;
    object-fit: cover;
  }

  .rightArrow {
    margin-left: 8px;
  }

  .profileContainer {
    > img {
      height: 70px;
      width: 70px;
    }
    display: flex;
    align-items: center;
  }

  .nameContainer {
    margin-left: 20px;
    height: auto;
    align-content: center;
  }

  .name {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 140%;
    color: #263857;
    margin-bottom: 4px;
  }

  .dob {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: #8494b1;
    margin-bottom: unset;
  }

  .dots {
    margin-top: 22px;
    cursor: pointer;
  }

  .customInput {
    border-radius: 0;
    font-weight: 400;
    font-size: 22px;
    color: #263857;
  }

  .customformLabel {
    font-size: 13px;
    line-height: 140%;
    color: #7e98ba;
    width: 100%;
    margin-bottom: 4px !important;
  }

  .seperator {
    border: 1px solid #e1e7f2;
    background-color: #e1e7f2;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .seperatorLegalGuardian {
    border: 1px solid #e1e7f2;
    background-color: #e1e7f2;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .expandMore {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
    margin-bottom: 8px;
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 140%;
    color: #7e98ba;
    cursor: pointer;
  }

  .downArrow {
    margin-left: 8px;
  }

  .upArrow {
    margin-left: 8px;
    margin-top: 2px;
    transform: rotate(180deg);
  }

  .legalGuardianContainer {
    background: #eff5fc;
    border-radius: 16px;
    padding: 24px;
    margin-top: 24px;
  }

  .legalGuardianProfileContainer {
    margin-top: 8px;
    display: flex;
    align-items: center;
    margin-bottom: 32px;
  }

  .defLegalGuardianImage {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  .legalGuardianName {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
    letter-spacing: 0.02em;
    color: #263857;
    margin-bottom: 4px;
  }

  .legalGuardianDob {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 140%;
    letter-spacing: 0.03em;
    color: #7e98ba;
    margin-bottom: unset;
  }

  .customLegalGuardianInput {
    font-size: 13px !important;
  }

  .customformLegalGuardianLabel {
    font-size: 11px !important;
    margin-bottom: 4px !important;
  }

  .collapsibles {
    margin-top: auto;
    background: white;
    width: 500px;
  }

  .downArrowImage {
    height: 80%;
  }

  .profileContainerFull {
    margin-top: 50px;
    margin-bottom: 64px;
    display: flex;
    justify-content: space-between;
  }

  .insuranceIcon {
    width: 14px;
    height: 16px;
    margin-bottom: 3px;
    margin-right: 5px;
  }

  .Modalfooter {
    width: 100%;
    padding: 0 32px 0 32px !important;
    position: absolute;
    bottom: 40px;
    border: 0 !important;
    justify-content: space-between !important;
    display: flex;
    .primaryBtn {
      background: #346bd1 !important;
      font-weight: 600;
      font-size: 13px;
      border-radius: 50px !important;
      padding: 10px 31px 10px 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      div {
        border-radius: 50px;
        background: white;
        height: 32px;
        width: 32px;
        justify-content: center;
        align-items: center;
        margin-right: 16px;
        display: flex;
      }
    }

    .deleteButton {
      background: #eff5fc;
      border-radius: 50px;
      font-weight: 600;
      font-size: 13px;
      border: 0;
      height: 52px;
      width: 52px;
      color: #8494b1;
    }

    .deleteButton:hover {
      background: #ecf2fa;
    }
  }
}
