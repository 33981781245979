$body-width: 352px;
$body-padding: 32px;

.modal {
  display: flex;
  justify-content: center;
  > div {
    background: none;
    border: 0;
    width: unset;
  }

  .body {
    background: #346bd1 !important;
    border-radius: 16px;
    width: $body-width;
    padding: $body-padding;

    .title {
      font-size: 22px;
      line-height: 140%;
      color: white;
      padding-bottom: 0;

      &.noSubtitle {
        margin-bottom: 32px;
      }
    }

    .subtitle {
      line-height: 140%;
      font-size: 16px;
      color: #b4cbf5;
      padding-bottom: 50px;
    }

    .buttonContainer {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 16px;

      .button {
        font-size: 13px;
        font-weight: 600;
        line-height: 140%;
        background: none;
        border: 1px solid #487ddf;
        border-radius: 50px;
        padding: 16px 0;
        width: 100%;
        &:hover {
          border: 1px solid #93b5f2;
        }
      }

      .buttonPrimary {
        background: #487ddf;
      }
    }
  }
}
