.fontSize13 {
  font-size: 13px;
}

.fontSize14 {
  font-size: 14px;
}

.lh0 {
  line-height: 0;
}
.lh1 {
  line-height: 1;
}

.lh140 {
  line-height: 140%;
}

.pl2,
.px2 {
  padding-left: 2rem !important;
}

.pr2,
.px2 {
  padding-right: 2rem !important;
}

.pb2 {
  padding-bottom: 2rem !important;
}

.rounded1 {
  border-radius: 1rem !important;
}
