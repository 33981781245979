$height: 110px;
$width: 405px;

.container {
  position: fixed;
  height: $height;
  width: $width;
  left: calc(50% - (#{$width} / 2)); // center based on calculation
  bottom: 56px;
  background-color: #346bd1;
  border-radius: 16px;
  z-index: 1051; // specific to be just greater than reactstrap's modal component
  padding: 23px 24px;
  display: flex;
  align-items: center;
}

.notificationIcon {
  margin-right: 24px;
  justify-content: center;
  border-radius: 50px;
  height: 50px;
  width: 50px;
  align-items: center;
}

.notificationTypeText {
  font-size: 16px;
  color: #8eb2f4;
}

.notificationMessage {
  font-size: 22px;
  color: #ffffff;
  font-weight: 600;
  line-height: 140%;
}

.bgSecondary {
  background-color: #3a79ee;
}

.failedIconText {
  color: red;
  text-align: center;
  font-size: 23px;
  margin-top: 13px;
}

.iconImg {
  margin-left: 14px;
  margin-top: 16px;
}
