.rootWrapper {
  margin: 0 110px 0 96px;
}

.nav-tabs {
  border-bottom: none !important;
  line-height: 0;

  > li {
    margin-bottom: 0 !important;

    > a {
      border: none !important;
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
      padding: 0;
      outline: none !important;
      font-family: Montserrat, sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #b6c0d1;
      margin-right: 48px;
      display: flex;
      cursor: pointer;
    }
  }
}

.contentWrapper {
  margin-top: 105px;
}
