.uploadWrapper {
  position: relative;
  &:hover {
    cursor: pointer !important;
  }

  .browseButton {
    font-weight: 600;
    font-size: 13px;
    border: 0;
    background: transparent;
    color: #346bd1;
  }

  input[type='file'] {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    opacity: 0;
  }
}

.modalHeader {
  padding: 40px 32px 32px !important;
  border: 0 !important;
  h5 {
    width: 100%;
  }
}

.customformLabel {
  font-size: 13px;
  line-height: 140%;
  color: #7e98ba;
  padding: 0 15px;
  width: 100%;
}

.birthDateLabel {
  font-size: 13px;
  line-height: 140%;
  color: #7e98ba;
  padding: 0 15px;
  width: 100%;
  margin-bottom: 20px;
}

.activeField {
  color: #346bd1;
}

.custominsuredNumber {
  font-size: 13px;
  line-height: 140%;
  color: #7e98ba;
  width: 100%;
}

.mainpopupHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.formLabel {
  padding-top: 40px;
  padding-bottom: 8px;
}

.customformLabelphonenumber {
  font-size: 13px;
  line-height: 140%;
  color: #346bd1;
}

form {
  .selectMenu {
    margin-bottom: 29px;
  }

  .customInput {
    border-left: 0;
    border-right: 0;
    border-top: 0;
    border-bottom: 1px solid #e1e7f2;
    border-radius: 0;
    font-weight: 500;
    font-size: 18px;
    color: #263857;
    margin-bottom: 32px;
    margin-top: 10px;
    padding-left: 0;

    &:disabled {
      background-color: white;
      border-bottom: 1px solid #e1e7f2;
      pointer-events: none;
    }
    &:focus {
      box-shadow: none;
      border-bottom: 1px solid #346bd1;
    }
    &:hover {
      border-bottom: 1px solid #8494b1;
    }
  }

  .fieldPlaceholder {
    position: absolute;
    pointer-events: none;
    top: 18px;
    transition: 0.2s ease all;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 140%;
    color: #7e98ba;
  }

  .fieldPlaceholderActive {
    position: absolute;
    pointer-events: none;
    top: -8px;
    transition: 0.2s ease all;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 140%;
    color: #346bd1;
  }

  .fieldPlaceholderInActive {
    position: absolute;
    pointer-events: none;
    top: -8px;
    transition: 0.2s ease all;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 140%;
    color: #7e98ba;
  }
  .hideFieldPlaceholder {
    visibility: hidden;
  }
  .guardianFieldPlaceHolder {
    top: 8px;
  }
  .guardianFieldPlaceholderActive {
    top: -18px;
  }
}

.modalBody {
  padding: 32px !important;
  background: white;
}

.nameField {
  top: 51px;
}

[type='radio'] {
  &:checked {
    position: absolute;
    left: -9999px;
    + {
      label {
        position: relative;
        padding-left: 28px;
        cursor: pointer;
        line-height: 20px;
        display: inline-block;
        color: #666;
        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 32px;
          height: 32px;
          border: 1px solid #ddd;
          border-radius: 100%;
          background: #ebf0f6;
        }
        &:after {
          content: url(https://storage.googleapis.com/dechea-microservices-files/patient-management/tick.svg);
          width: 32px;
          height: 32px;
          background: #346bd1;
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100%;
          -webkit-transition: all 0.2s ease;
          transition: all 0.2s ease;
          opacity: 1;
          -webkit-transform: scale(1);
          transform: scale(1);
        }
      }
    }
  }
  &:not(:checked) {
    position: absolute;
    left: -9999px;
    + {
      label {
        position: relative;
        padding-left: 28px;
        cursor: pointer;
        line-height: 20px;
        display: inline-block;
        color: #666;
        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 32px;
          height: 32px;

          border-radius: 100%;
          background: #ebf0f6;
        }
        &:after {
          content: '';
          width: 32px;
          height: 32px;
          background: #f87da9;
          position: absolute;
          top: 4px;
          left: 4px;
          border-radius: 100%;
          -webkit-transition: all 0.2s ease;
          transition: all 0.2s ease;
          opacity: 0;
          -webkit-transform: scale(0);
          transform: scale(0);
        }
      }
    }
  }
}

.squareRadio {
  > input[disabled] + label {
    cursor: unset !important;
  }
}

.nameInput {
  top: 14px;
}

.firstRow {
  align-items: flex-end;
  margin-left: unset !important;
  margin-right: unset !important;
  width: 100%;
}

.birthmonth {
  position: relative;
  bottom: 30px;
}

.customDate {
  display: flex;
  align-items: flex-end;
  position: relative;
  bottom: 12px;
}

.squareRadio [type='checkbox'] {
  &:checked {
    position: absolute;
    left: -9999px;
    + {
      label {
        position: relative;
        padding-left: 28px;
        cursor: pointer;
        line-height: 20px;
        display: inline-block;
        color: #666;
        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 32px;
          height: 32px;
          border: 1px solid #ddd;
          background: #ebf0f6;
          border-radius: 8px !important;
        }
        &:after {
          content: url(https://storage.googleapis.com/dechea-microservices-files/patient-management/tick.svg);
          width: 32px;
          height: 32px;
          background: #346bd1;
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          align-items: center;
          border-radius: 8px !important;
          justify-content: center;
          -webkit-transition: all 0.2s ease;
          transition: all 0.2s ease;
          opacity: 1;
          -webkit-transform: scale(1);
          transform: scale(1);
        }
      }
    }
  }
  &:not(:checked) {
    position: absolute;
    left: -9999px;
    + {
      label {
        position: relative;
        padding-left: 28px;
        cursor: pointer;
        line-height: 20px;
        display: inline-block;
        color: #666;
        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 32px;
          height: 32px;
          border-radius: 8px !important;
          background: #ebf0f6;
        }
        &:after {
          content: '';
          width: 32px;
          height: 32px;
          background: #f87da9;
          position: absolute;
          top: 4px;
          border-radius: 8px !important;
          left: 4px;
          -webkit-transition: all 0.2s ease;
          transition: all 0.2s ease;
          opacity: 0;
          -webkit-transform: scale(0);
          transform: scale(0);
        }
      }
    }
  }
}

.labelfield {
  font-weight: 600;
  font-size: 16px;
  color: #8494b1 !important;
  padding: 4px 0 4px 50px !important;
}

.blockField {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;

  p {
    margin-bottom: 0;

    > input[disabled] + label {
      cursor: unset;
    }
  }
}

.Modalfooter {
  position: absolute;
  bottom: 40px;
  border: 0 !important;
  justify-content: flex-start !important;
  display: flex;
  .primaryBtn {
    background: #346bd1 !important;
    font-weight: 600;
    font-size: 13px;
    border-radius: 50px !important;
    padding: 10px 31px 10px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    div {
      border-radius: 50px;
      background: white;
      height: 32px;
      width: 32px;
      justify-content: center;
      align-items: center;
      margin-right: 16px;
      display: flex;
      .createImg {
        width: 12px;
      }
      .editImg {
        height: 10px;
        width: 14px;
      }
    }
  }

  .primaryBtn:focus,
  .primaryBtn:active {
    outline: none !important;
    box-shadow: none;
  }

  .disableBtn {
    background: #ecf2fa !important;
    color: #7e98ba;
    border-width: 0;
  }

  .cancelBtn {
    background: #ebf0f6;
    border-radius: 50px;
    font-weight: 600;
    font-size: 13px;
    border: 0;
    color: #263857;
    padding: 18px 37px;
    margin-left: 16px;
  }

  .cancelBtn:hover {
    background: #ecf2fa;
  }
}

@keyframes slide-in {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}
:global(.modal) {
  .modalMain {
    height: 100%;
    width: 500px !important;
    animation: slide-out 0.2s forwards;
    -webkit-animation: slide-out 0.2s forwards;
    right: 0;
    position: absolute;
    margin: 0;

    > div {
      height: 100%;
      border-radius: unset;
    }
  }
}

.fadeout {
  right: -100vw !important;
  position: absolute;
  animation: slide-in 0.2s backwards;
  -webkit-animation: slide-in 0.2s backwards;
}

.fadein {
  width: 100%;
  position: unset;
  opacity: 1;
}

.defImage {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
}

input {
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    box-shadow: 0 0 0 30px white inset !important;
    &:hover {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
      box-shadow: 0 0 0 30px white inset !important;
    }
    &:focus {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
      box-shadow: 0 0 0 30px white inset !important;
    }
    &:active {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
      box-shadow: 0 0 0 30px white inset !important;
    }
  }
}

.genderLabel {
  font-size: 13px;
  line-height: 140%;
  color: #7e98ba;

  width: 100%;
  padding-left: 0;
}
.feedbackForm {
  display: inline-block;
  font-size: 10px;
  color: #dc3545;
  top: -14px;
  position: relative;
}

.streetfeedbackForm {
  display: inline-block;
  font-size: 10px;
  color: #dc3545;
  top: -35px;
  position: relative;
}

.formCol {
  position: relative;
}
