$icon-size: 32px;
$menu-width: 180px;

.dots {
  width: $icon-size;
  height: $icon-size;
  cursor: pointer;
}

.item {
  display: flex;
  width: $menu-width;
  cursor: pointer;

  > img {
    margin-left: 28px;
  }
}

.label {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 140%;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #dae2ef;
  margin-left: 5px;
  padding: 24px 0 24px 10px;
}

.itemGroup {
  background: #263857;
  box-shadow: 0 25px 70px rgba(53, 69, 98, 0.15),
    0 20px 50px rgba(53, 69, 98, 0.15);
  backdrop-filter: blur(144px);
  border-radius: 12px;
  position: absolute;
  margin-left: -165px;
  margin-top: -28px;
  z-index: 2;

  &:hover {
    background-color: #1c3663;
  }
}

.item:not(:last-child) {
  border-bottom: 1px solid #3c4e6f;
}
