body {
  margin: 0;
  font-family: Montserrat, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.tab-pane.active {
  animation-name: fadeIn;
  animation-duration: 200ms;
  animation-timing-function: linear;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #7e98ba !important;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #7e98ba !important;
  opacity: 1;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #7e98ba !important;
  opacity: 1;
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #7e98ba !important;
}
::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #7e98ba !important;
}

::placeholder {
  /* Most modern browsers support this now. */
  color: #7e98ba !important;
}

.modal-content {
  border: none;
}
