.rootWrapper {
  margin: 84px 110px 32px 96px;
  display: flex;
  justify-content: space-between;
}

.title {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 41px;
  line-height: 57px;
  color: #263857;
}

.buttonWrapper {
  background: #346bd1;
  border-radius: 50px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.newBtn {
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #ffffff;
  padding: 17px 30px 17px 16px;
}

.newBtn:focus {
  outline: none;
}

.plusIcon {
  padding-bottom: 3px;
  vertical-align: middle;
}
.plus {
  color: #346bd1;
  padding: 0 11px;
  border-radius: 50px;
  background: white;
  font-size: 25px;
  margin: 10px;
}
