.active {
  color: #263857 !important;
}

.active::after {
  height: 4px;
  width: 32px;
  content: "";
  background: #346bd1;
  position: absolute;
  margin-top: 38px;
  border-radius: 2px;
}
