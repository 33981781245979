.nestedModal {
  position: absolute !important;
  right: 527px;

  > {
    div {
      background: none;
      border: 0;
      position: relative;
      top: 226px;
    }
  }
}

.warningModal {
  background: #346bd1 !important;
  border-radius: 16px;
  width: 352px;
  padding: 32px;
}

.warningModalTitle {
  font-size: 22px;
  color: white;
  padding-bottom: 0;
}

.warningModalBody {
  line-height: 140% !important;
  font-size: 16px;
  color: #b4cbf5;
  padding-bottom: 50px;
}

.nestedButtonClass {
  padding: 0 16px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.polygon {
  position: absolute;
  right: -13px;
  top: 45px;
}

.nestedButton {
  background: none !important;
  border: 1px solid #487ddf !important;
  border-radius: 50px !important;
  padding: 17px 51px !important;
  margin-right: 15px;
  width: 100%;
  line-height: 18.2px;
  &:hover {
    background: #487ddf !important;
  }
}
