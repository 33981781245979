.profileImage {
  height: 56px;
  width: 56px;
  border-radius: 100px;
  object-fit: cover;
}

.statusRow,
.archiveRow {
  position: relative;
}

.statusRow {
  ::-webkit-scrollbar {
    width: 4px;
    height: 50px;
  }
  ::-webkit-scrollbar-track {
    opacity: 0;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: #7e98ba;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #7e98ba;
    border-radius: 2px;
  }
}

.rootWrapper {
  > table {
    margin-bottom: 64px;

    > tbody {
      color: #8494b1;

      > tr {
        border-top: 1px solid #dee2e6;
        > td {
          position: relative;
          padding: 0;
          vertical-align: middle;
          height: 124px;
          border-top: unset;
        }
      }

      > tr:hover + tr {
        border-color: transparent !important;
      }

      > tr:hover {
        background: #eff5fc;
        border-top-color: transparent;
      }
    }

    > thead {
      color: #b6c0d1;
      > tr > th {
        border-top-color: transparent;
        border-bottom-color: transparent !important;
        font-family: Montserrat, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        padding: 0 0 24px 0;
        cursor: pointer;
      }
    }
  }
}

.nameWrapper {
  display: flex;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #8494b1;
  margin-left: 16px;
}

.highlightNameWrapper {
  display: flex;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #263857 !important;
  margin-left: 16px;
  cursor: pointer;
}

.ageWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 24px;
}

.age {
  font-size: 13px;
  line-height: 18px;
  padding-top: 4px;
}

.address {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  cursor: pointer;
}

.highlightAddress {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #263857 !important;
  cursor: pointer;
}

.dob {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.02em;
  font-feature-settings: "tnum" on, "lnum" on;
  cursor: pointer;
}

.highlightDob {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.02em;
  font-feature-settings: "tnum" on, "lnum" on;
  color: #263857 !important;
  cursor: pointer;
}

.hidden {
  padding-left: 12px;
  height: 10px;
  margin-bottom: 2px;
  visibility: hidden;
}

.headerFont {
  color: #8494b1;
  cursor: pointer;
}

.minWidth {
  min-width: 145px;
}

.appointmentHeader {
  color: #8494b1;
  cursor: pointer;
  min-width: 145px;
}

.appointmentContent {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  font-feature-settings: "tnum" on, "lnum" on;
  cursor: pointer;
}

.highlightAppointmentContent {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  font-feature-settings: "tnum" on, "lnum" on;
  color: #263857;
  cursor: pointer;
}

.circleYellow {
  height: fit-content;
  padding: 4px;
  border: 2px solid #f9ce60;
  border-radius: 50%;
}

.circleGreen {
  height: fit-content;
  padding: 4px;
  border: 2px solid #36dcaa;
  border-radius: 50%;
}

.circleOrange {
  height: fit-content;
  padding: 4px;
  border: 2px solid #ed5611;
  border-radius: 50%;
}

.circleBlue {
  height: fit-content;
  padding: 4px;
  border: 2px solid #0a7291;
  border-radius: 50%;
}

.circleNone {
  height: fit-content;
  padding: 4px;
  border: 2px solid transparent;
}

.defImage {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.bellIcon {
  height: 30px;
  width: 30px;
  position: absolute;
  top: 47px;
  left: -41px;
}

.arrowImage {
  padding-left: 12px;
  height: 10px;
  margin-bottom: 2px;
}

.tableRow {
  cursor: pointer;
}

.btnRestore {
  display: flex;
  justify-content: center;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18.2px;
  width: 7rem;
  padding: 17px 30px;
  color: #263857;
  background-color: #eff5fc;
  border: none;
  border-radius: 50px;
  outline: none;
  &:focus {
    outline: none;
  }
}

.btnRestore:hover {
  background: #ecf2fa;
}

.insuranceIcon {
  width: 12px;
  height: 12px;
  margin-right: 4px;
}

.customformLabel {
  display: flex;
  align-items: center;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
}
